<script setup lang="ts">
import { withLeadingSlash } from 'ufo'
import type { Collections } from '@nuxt/content'

const props = withDefaults(
  defineProps<{
    hideTitle?: boolean
  }>(),
  {
    hideTitle: false,
  },
)

interface TocLink {
  id: string
  text: string
}

const tocLinks = ref<TocLink[]>([])
const route = useRoute()
const { locale } = useI18n()

const path = computed(() => withLeadingSlash(String(route.params.slug).replaceAll(',', '/')))

const { data: page } = await useAsyncData(
  'content:page-toc-list:' + route.path,
  async () => {
    const collection = getContentCollection('all', locale.value).name as keyof Collections
    const content = await queryCollection(collection).path(path.value).first()
    return content
  },
  { watch: [locale] },
)

if (page.value) {
  const content = page.value
  tocLinks.value = content.body?.toc ? content.body?.toc.links : []
}
</script>

<template>
  <nav>
    <span v-if="!props.hideTitle" class="toc">{{ $t('TOC') }} </span>
    <ul>
      <li v-for="link of tocLinks" :key="link.id">
        <NuxtLink :to="`#${link.id}`">{{ link.text }}</NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<style lang="css" scoped>
.toc {
  font-weight: 500;
  font-size: 1.2em;
  text-transform: uppercase;
}
</style>
